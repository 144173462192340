import { NextRouter } from 'next/router';

import axios, { AxiosError, AxiosInstance, CreateAxiosDefaults, HttpStatusCode } from 'axios';
import cookies from 'js-cookie';

import { updateToken } from '@/api/auth/login';
import { REFRESH_TOKEN_NAME, TOKEN_COOKIE_NAME, USER_LOGGED_KEY_NAME } from '@/auth/constants';
import { LocalStorage } from '@/utils/local-storage';

type RequestServiceConfig = {
	axiosConfig?: CreateAxiosDefaults;
	isServerSide?: boolean;
};

const DefaultRequestConfig: RequestServiceConfig = {
	axiosConfig: {},
	isServerSide: false,
} as const;

export class RequestService {
	private static instance: AxiosInstance;
	private static router: NextRouter;
	private static refreshing: boolean = false;

	public static getInstance(config: RequestServiceConfig = DefaultRequestConfig) {
		if (!RequestService.instance) {
			RequestService.instance = axios.create({
				baseURL: process.env.HOST,
				...(config.axiosConfig ?? {}),
			});
		}
		return RequestService.instance;
	}

	public static setAuthHeader(value: string) {
		if (!RequestService.instance) {
			throw new Error('Axios instance has not been created');
		}
		RequestService.instance.defaults.headers['Authorization'] = `Bearer ${value}`;
	}

	public static addInterceptor() {
		RequestService.instance.interceptors.response.use(
			(response) => {
				return response;
			},
			async (error: AxiosError) => {
				if (error?.response?.status === HttpStatusCode.Unauthorized) {
					if (error.config.url.includes('refresh')) {
						cookies.remove(TOKEN_COOKIE_NAME);
						cookies.remove(REFRESH_TOKEN_NAME);
						LocalStorage.removeItem(USER_LOGGED_KEY_NAME);

						return Promise.reject(error);
					}
					if (RequestService.refreshing) {
						return Promise.reject(error);
					}
					RequestService.refreshing = true;
					try {
						const { access } = await updateToken();
						RequestService.setAuthHeader(access);

						cookies.set(TOKEN_COOKIE_NAME, access);
						LocalStorage.setItem(USER_LOGGED_KEY_NAME, 'true');

						return axios.request({
							...error.config,
							headers: {
								Authorization: `Bearer ${access}`,
							},
						});
					} catch (e) {
						cookies.remove(TOKEN_COOKIE_NAME);
						cookies.remove(REFRESH_TOKEN_NAME);
						LocalStorage.removeItem(USER_LOGGED_KEY_NAME);
					} finally {
						RequestService.refreshing = false;
					}
				}

				return Promise.reject(error);
			},
		);
	}
}
