import { HTMLProps, useEffect, useRef, useState } from 'react';

import clsx from 'clsx';

import useOnClickOutside from '@/hooks/use-on-click-outside';
import usePrevious from '@/hooks/use-previous';
import { useTranslate } from '@/hooks/use-translate';
import escapeRegExp from '@/utils/escape-regexp';

import { en } from './en';

import './LabeledSelect.scss';

export type LabeledSelectOption = {
	value: string;
	text: string;
};

type LabeledSelectProps = Omit<HTMLProps<HTMLInputElement>, 'onChange'> & {
	test?: boolean;
	/** Если указан, добавляет поиск */
	search?: boolean;
	searchRegExp?: RegExp;
	onSearch?: (search: string) => void;
	/** Если указан, показывает прелоадер */
	loading?: boolean;
	label?: string;
	/**
	 * Очень плохое решение. Если `options` пустой, то используется фоллбэк `value`.
	 * Данный пропс является более приоритетным фоллбэком (сначала используется `text`, и уже если нет его, то `value`)
	 **/
	text?: string;
	options: string[] | LabeledSelectOption[];
	customValueAvailable?: boolean;
	alternativeVariant?: string;
	/** Если указан, добавляет дефолтную опцию, равноценную "не выбрано" */
	resetOption?: string;
	hideArrow?: boolean;
	format?: (value: string) => string;
	/** TODO: Переименовать в `required` */
	important?: boolean;
	/** Добавляет подсветку и указанный текст с ошибкой */
	error?: string;
	onChange?: (value: string, options: string[]) => void;
	/** Скрывать выбранный option в выпадающем списке */
	hideSelected?: boolean;
};

function adaptOptions(options: string[] | LabeledSelectOption[]): LabeledSelectOption[] {
	return options?.map((option) => ({
		value: option?.value ?? option,
		text: option?.text ?? option,
	}));
}

// TODO: Добавить навигацию с клавиатуры
export default function LabeledSelect(props: LabeledSelectProps) {
	const {
		test,
		className,
		label,
		text,
		name,
		loading,
		customValueAvailable,
		hideArrow,
		alternativeVariant,
		important,
		disabled,
		error,
		onSearch,
		onChange,
		onBlur,
		hideSelected = false,
	} = props;

	const t = useTranslate(en);

	const placeholder = props.placeholder ?? t('Выберите вариант');

	const format = props.format ?? ((value) => value);

	const ref = useRef<HTMLInputElement>(null);
	const labelRef = useRef<HTMLLabelElement>(null);
	const [value, setValue] = useState(props.value);
	const prevValue = usePrevious(value);
	const [isDroplistVisible, setIsDroplistVisible] = useState(false);
	const isSearchAvailable = props?.search ?? true;

	useEffect(() => {
		setValue(props.value);
	}, [props.value]);

	useEffect(() => {
		if (isSearchAvailable) {
			setSearch(null);
		}
		if (value !== prevValue) {
			onChange?.(
				value as string,
				props.options.map((option) => option?.value ?? option),
			);
		}
	}, [value]);

	const [searchFocused, setSearchFocused] = useState(false);
	const [search, setSearch] = useState('');
	const [options, setOptions] = useState<LabeledSelectOption[]>();
	useEffect(() => {
		setOptions(adaptOptions(props.options));
	}, [props.options]);

	useEffect(() => {
		if (!isSearchAvailable) return;

		const options = adaptOptions(props.options)?.filter((option) =>
			new RegExp(escapeRegExp(search ?? ''), 'i').test(option.text),
		);
		setOptions(options);

		if (loading) {
			// Закрываем селект во время загрузки данных
			setIsDroplistVisible(false);
		} else {
			if (searchFocused) {
				setIsDroplistVisible(options?.length > 0);
			}
		}
	}, [search, isSearchAvailable, props.options]);

	useOnClickOutside(labelRef, () => {
		setIsDroplistVisible(false);
	});

	const resetOption = props?.resetOption || options?.length === 0;

	return (
		<label
			ref={labelRef}
			className={clsx('select__container', error && 'error', className)}
			onClick={(e) => {
				e.preventDefault();
				if (disabled) return;
				if (options?.length || resetOption) {
					setIsDroplistVisible(!isDroplistVisible);
				}
			}}
		>
			{label && (
				<span>
					{label}
					{important && <span style={{ color: 'red', marginLeft: '.15em' }}>*</span>}
				</span>
			)}

			<div className="relative">
				{!customValueAvailable ? (
					isSearchAvailable ? (
						// TODO: Добавить автофокус по клике на label
						<input
							className={clsx('select__plate', disabled && 'disabled')}
							autoComplete="off"
							disabled={disabled}
							value={
								search ??
								adaptOptions(props.options)?.find((option) => option?.value == value)?.text ??
								text ??
								value ??
								''
							}
							placeholder={placeholder}
							onFocus={(e) => {
								e.preventDefault();
								e.currentTarget.select();
								setSearchFocused(true);
							}}
							onBlur={(e) => {
								if (
									options
										.map((o) => o?.text ?? o)
										.find((o) => {
											return o === search;
										}) === null
								) {
									setSearch(null);
									setValue('');
								}
								setSearchFocused(false);
								onBlur && onBlur(e);
							}}
							onInput={(e) => {
								props.onInput && props?.onInput(e);
							}}
							onChange={(e) => {
								const value = e.target.value;
								if (props.searchRegExp && !value.match(props.searchRegExp)) {
									return;
								}

								setSearch(value);
								if (onSearch) {
									onSearch(value);
								}
							}}
						/>
					) : (
						<>
							<b
								className={clsx(
									'select__plate max-w-full !block !pr-[3.5em] whitespace-nowrap overflow-hidden text-ellipsis',
									disabled && 'disabled',
								)}
								style={{ color: value ? 'black' : 'rgba(10, 38, 83, 0.3)' }}
							>
								{!value
									? placeholder
									: format(
										options?.find((option) => option.value === value)?.text ?? (value as string),
									)}
							</b>

							<input
								className={clsx(disabled ? 'disabled' : '')}
								type="hidden"
								name={name ?? label}
								value={value}
								placeholder={placeholder}
							/>
						</>
					)
				) : (
					<input
						ref={ref}
						className={clsx('select__plate', disabled && 'disabled')}
						disabled={disabled}
						autoComplete="off"
						style={{ color: value ? 'black' : 'rgba(10, 38, 83, 0.3)' }}
						name={name ?? label}
						value={value}
						placeholder={placeholder}
						onChange={(e) => {
							if (disabled) return;
							setValue(e.target.value);
							setIsDroplistVisible(false);
						}}
					/>
				)}

				{(resetOption || (!hideArrow && !loading && options?.length > 0)) && (
					<svg
						style={isDroplistVisible ? { rotate: '180deg', marginTop: '0.1rem' } : {}}
						className={clsx('select-arrow', disabled && 'disabled')}
						width="10"
						height="10"
						fill="none"
						viewBox="0 0 11 11"
					>
						<path
							fill="var(--accent-color)"
							d="M10.5 1.17a1 1 0 0 0-1.41 0L5.5 4.71 1.96 1.17A1 1 0 1 0 .55 2.59l4.24 4.24a1 1 0 0 0 1.42 0l4.29-4.24a1 1 0 0 0 0-1.42Z"
						/>
					</svg>
				)}

				{loading && (
					<svg className="select-preloader" width="10" height="10" viewBox="0 0 24 24">
						<path fill="none" d="M0 0h24v24H0z" />
						<path
							fill="var(--accent-color)"
							d="M3.055 13H5.07a7.002 7.002 0 0 0 13.858 0h2.016a9.001 9.001 0 0 1-17.89 0zm0-2a9.001 9.001 0 0 1 17.89 0H18.93a7.002 7.002 0 0 0-13.858 0H3.055z"
						/>
					</svg>
				)}
			</div>

			<div className={clsx('select__droplist', isDroplistVisible && 'active')}>
				<ul
					className={clsx(
						options?.length + (resetOption ? 1 : 0) > 4 ? 'long' : 'short',
						loading && 'loading',
					)}
				>
					{/* TODO: Сделать опции кнопками */}
					{/* TODO: Помечать выбранный элемент */}
					{resetOption && (
						<li
							onClick={() => {
								setValue('');
								setSearch(null);
								if (prevValue !== value) {
									onChange?.(
										'',
										options.map((option) => option?.value),
									);
								}
							}}
						>
							<span>{resetOption}</span>
						</li>
					)}
					{options?.map((option, index) => {
						return (
							<li
								className={
									hideSelected && `${value}` === `${option.value}` ? 'visually-hidden' : ''
								}
								key={`${option.value}${index}`}
								onClick={() => {
									setValue(option.value);
									if (prevValue === option.value) {
										onChange(
											prevValue as string,
											props.options.map((option) => option?.value ?? option),
										);
									}
								}}
							>
								{/* TODO: Убрать `span` */}
								<span>{format(option.text)}</span>
							</li>
						);
					})}
					{customValueAvailable && (
						<li
							onClick={() => {
								setValue('');
								ref.current?.focus();
							}}
						>
							{alternativeVariant ?? 'Др. способ (указать какой)'}
						</li>
					)}
				</ul>
			</div>

			{error && <span className="error-text">{error}</span>}
		</label>
	);
}
