import cookies from 'js-cookie';

import { RequestService } from '@/api/services/request.service';
import { REFRESH_TOKEN_NAME } from '@/auth/constants';
import { CompanyType } from '@/types/common-entities';

type LoginData = {
	email: string;
	password: string;
};

type AuthDataResponse = {
	access: string;
	refresh: string;
	company_type: CompanyType;
};

type RefreshTokenResponse = {
	access: string;
};

type AuthData = {
	token: string;
	refresh: string;
	companyType: CompanyType;
};

export const login = async (loginData: LoginData): Promise<AuthData> => {
	const request = RequestService.getInstance();
	const { email, password } = loginData;
	const { data } = await request.post<AuthDataResponse>('/accounts/token/', {
		email: email.toLowerCase(),
		password: password,
	});
	return {
		token: data.access,
		refresh: data.refresh,
		companyType: data.company_type,
	};
};

export const updateToken = async (): Promise<RefreshTokenResponse> => {
	const request = RequestService.getInstance();
	const refresh = cookies.get(REFRESH_TOKEN_NAME);
	const { data } = await request.post<AuthDataResponse>(
		'/accounts/token/refresh/',
		{ refresh },
		{
			withCredentials: true,
		},
	);
	return data;
};
